/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:12e1f0ab-9316-4629-9b41-00f27c04a56e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_KMbOtCydj",
    "aws_user_pools_web_client_id": "2m2co9tie8lvlos77v2pevingj",
    "oauth": {}
};


export default awsmobile;
